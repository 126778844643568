import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import Iframe from 'react-iframe'
import Helmet from "react-helmet"

export const BookPageTemplate = ({
  formEmbed
}) => {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.src = 'https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js';
    document.body.appendChild(script1);
  
    script1.onload = () => {
      if (typeof window.jotformEmbedHandler === 'function') {
        const script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.textContent = `window.jotformEmbedHandler("iframe[id='JotFormIFrame']", "https://form.jotform.com/");`;
        document.body.appendChild(script2);
      }
    }
  }, []);

  const sectStyle = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
     backgroundColor: "#2c2c2c"
  };

  return (
    <div>
      {/* <FullWidthImage img={heroImage} height={ "90vh"} subheading={"Packing List"}/> */}
      <div className="section section--gradient" style={sectStyle}>
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <div className="content">
                <div className="padding_bot">
                  <div className="content">
                    <div className="columns is-multiline ">
                      <div className="column">
                          {/* <h1>{formEmbed}</h1> */}
                          <div className="cardcontainer">
                            <iframe
                              id="JotFormIFrame"
                              title="WCBS 2024 Booking form"
                              allowtransparency="true"
                              allow="geolocation; microphone; camera; fullscreen"
                              src={formEmbed} 
                              frameBorder="0"
                              style={{ minWidth: "100%", maxWidth: "100%", height: "539px", border: "none" }}
                              scrolling="no"
                              onLoad={() => {
                                window.parent.scrollTo(0, 0);
                              }}
                            ></iframe>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BookPageTemplate.propTypes = {
  formEmbed: PropTypes.string
};

const Book = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <BookPageTemplate
        formEmbed={frontmatter.formEmbed}
      />
    </Layout>
  );
};

Book.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default Book;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        formEmbed
      }
    }
  }
`;
